<template>
   <!-- Home Area Start --> 
    <section id="home" class="bg-white pt-48 pb-10" >   
      <div class="container" data-aos-mirror="true">
        <div class="flex justify-between">
          <div class="w-full text-center">
            <div class="item-center animated" data-aos="zoom-in-down">
              <img class="img-fluid mx-auto" src="../assets/img/logo_TB.png" alt="Logo TB"/>
            </div>
            <h1 class="text-2xl font-bold leading-snug text-gray-700 mb-10" data-aos="fade-up">
              Somos una empresa dedicada al ramo de mensajería y paquetería LTL especializados en recolección, distribución y entrega de última milla para envíos en la CDMX, Zona Metropolitana y estados de la República Mexicana.
            </h1>
            <div class="text-center mb-10 animated" data-aos="fade-up"> 
              <div class="submit-button">
                <a class="btn uppercase m-4" href="#" type="submit"> Cotización </a>
                <a class="btn uppercase m-4" href="https://sistema.logisticosbada.com/" target="_blank" type="submit"> Rastreo </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <!-- Home Area End -->
</template>

<script>
export default {
    name: 'homeComponent'
}
</script>

<style scoped>

</style>