<template>
    <section class="pt-48 pb-10">
        <div class="container" data-aos-mirror="true">
            <div class="text-center">
                <h2 class="mb-12 text-4xl text-gray-700 tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Términos y Condiciones </h2>
                <div class="mb-12 text-gray-700 tracking-wide uppercase font-bold" data-aos="zoom-in-down">
                    CONTRATO DE PRESTACIÓN DE SERVICIOS DE MENSAJERÍA Y PAQUETERÍA <br>
                    TRANSPORTES LOGÍSTICOS BADA
                </div>
            </div>
            <div class="flex flex-wrap contact-form-area justify-center" data-aos="fade-down">
                <div class="w-full md:w-3/4 py-8 text-gray-700">
                    <p>
                        Contrato de prestación de servicio de mensajería y paquetería que celebran por una parte
                        <b>“BARBARA ANGELICA DENA ARADILLAS”</b>, Persona física con actividad empresarial, a 
                        quien comercialmente se le conoce como <b>“TRANSPORTES LOGISTICOS BADA”</b> y en lo 
                        sucesivo se le denominará <b>“El PROVEEDOR”</b> y por otra parte el remitente en lo sucesivo 
                        <b>“EL CLIENTE”</b> cuyos datos aparecen en la guía de servicio, carta porte o factura amparado 
                        por el presente contrato conforme a las siguientes:  
                    </p>
                    <br><br>
                    <p class="text-center">
                        <b>DECLARACIONES</b>
                    </p>
                    <p> 
                        <ol class="ol-list pl-3">
                            <li>
                                Declara <b>“EL PROVEEDOR”</b> bajo protesta de decir verdad:
                                <ul class="ul-list-ii">
                                    <li> Ser persona física, con actividad empresarial, mexicana, mayor de edad, en pleno uso y goce de sus facultades y derechos. </li>
                                    <li> Que su Registro Federal de Contribuyentes (RFC) es: DEAB870727HV0. </li>
                                    <li> Que señala como domicilio para oír y recibir todo tipo de notificaciones el ubicado en Granate, No. 112, Colonia Estrella, Alcaldía Gustavo A. Madero, Ciudad de México, C.P. 07810. </li>
                                </ul>
                            </li>
                            <li>
                                Declara <b>“EL CLIENTE”</b> bajo protesta de decir verdad:
                                <ul class="ul-list-ii">
                                    <li> Llamarse como ha quedado documentado en la Guía de Servicio. </li>
                                    <li> Que es su deseo obligarse en los términos y condiciones del presente Contrato. </li>
                                    <li> Que señala como domicilio para oír y recibir todo tipo de notificaciones el señalado en los datos de la Guía de Servicio. </li>
                                </ul>
                            </li>
                        </ol>
                        Las partes dan su conformidad sobre el servicio contratado, y se sujetan a las siguientes:
                    </p>
                    <br><br>
                    <p class="text-center">
                        <b>CLAUSULAS</b>
                    </p>
                    <p> 
                        <ol class="ol-list pl-3">
                            <li>
                                <b> SERVICIOS. </b> <br>
                                “EL PROVEEDOR” se obliga a prestar a favor de “EL CLIENTE” el servicio de mensajería y 
                                paquetería, documentos y/o paquetes con mercancías amparado en una guía de servicio o carta 
                                porte y/o factura, en el domicilio del destinatario designado por “EL CLIENTE” el plazo se contará a 
                                partir de la recepción y documentación del envío, por lo que deben considerarse los plazos de entrega del 
                                PROVEEDOR publicados en su Lista de Precios. 
                                <br>
                                “El CLIENTE” podrá contratar los siguientes servicios de acuerdo a lo que a continuación se detalla: 
                                <br>
                                <ul class="ul-list-ii">
                                    <li> Mensajería: envase con capacidad de hasta 2 kilogramos, para documentos no negociables. </li>
                                    <li> Paquetería: mercancía debidamente empacada, que en sus dimensiones (L)x(A)x(A)/5000 en cm; no 
                                         exceda a 30kg. en cualquiera de los lados y con un peso máximo de 30 kg. por paquete.
                                    </li>
                                </ul>
                            </li>
                            <br>
                            <li>
                                <b> Responsabilidad de “EL PROVEEDOR”. </b><br>
                                La responsabilidad de “EL PROVEEDOR”, inicia a la aceptación por parte de ésta en guía o carta porte 
                                y/o factura y cesa cuando (i) el Paquete sea entregado al destinatario y/o al tercero 
                                autorizado, (entendiendo como tercero autorizado a la persona distinta al destinatario que se encuentre 
                                en el domicilio autorizado por el remitente o “EL CLIENTE” que recibe el Envío o que cuenta con 
                                autorización del destinatario para recoger el/los Envío(s) objeto del servicio) o (ii) cuando el Envío sea 
                                retirado de la posesión de “EL PROVEEDOR” por orden de autoridad competente, siendo 
                                responsabilidad de “EL CLIENTE” la revisión del empaque y la declaración inmediata a “EL 
                                PROVEEDOR” de cualquier daño o violación al empaque, considerando que la recepción se entiende 
                                como una recepción a total satisfacción del Paquete que se trate. 
                                <br>
                                “EL PROVEEDOR” se compromete a dar el servicio con todo esmero, así como a guardar absoluta 
                                reserva y confidencialidad respecto de la información que contienen los servicios.
                            </li>
                            <br>
                            <li>
                                <b> Obligaciones de “EL CLIENTE”. </b><br>
                                “El Cliente” se obliga ante “EL PROVEEDOR” a:
                                <ul class="ul-list-ii">
                                    <li> a)	Pagar por el servicio contratado según los precios vigentes y los impuestos que señale la autoridad de ser procedentes; </li>
                                    <li> b)	Responder por los artículos objeto de los servicios y contar con los documentos que demuestren su propiedad y preexistencia, reconociendo que no serán objeto del servicio la mercancía enlistada en el Artículo 18 del Reglamento de Paquetería y Mensajería y será responsabilidad de “EL CLIENTE” indemnizar y sacar en paz y a salvo a “EL PROVEEDOR” de cualquier responsabilidad por incumplimiento a esta obligación; </li>
                                    <li> c)	Indemnizar y sacar en paz y a salvo a “EL PROVEEDOR” o representantes de esta contra cualquier reclamación, demanda o daño o perjuicio por causas imputables a “EL CLIENTE”; </li>
                                    <li> d)	Pagar a “EL CLIENTE” por estadía o almacenaje de los Paquetes, en caso de que el Paquete no sea recibido por el destinatario o, los datos del destinatario sean incorrectos, se lleve a cabo la devolución de este a “EL CLIENTE” y/o remitente por causas no imputables a “EL PROVEEDOR”; </li>
                                    <li> e)	Proporcionar en forma precisa los datos en la guía; </li>
                                    <li> f)	Declarar con veracidad el contenido del Paquete, asumiendo la responsabilidad que legalmente le corresponda; </li>
                                    <li> g)	Garantizar que los bienes objeto de los servicios no son objeto de delito y cumplir con la debida diligencia para prevención de lavado de dinero; </li>
                                    <li> h)	Informar el peso exacto de sus envases y paquetes, autorizando a “EL PROVEEDOR” a aplicar la fórmula dimensional, aplicable, y contenida en la guía de servicio, así como utilizar un embalaje o empaque que no permita el movimiento interno del contenido y que dañe otros paquetes o el equipo operacional; y </li>
                                    <li> i)	Utilizar los envases apropiados aceptados por “EL PROVEEDOR”, exclusivamente para este fin. </li>
                                    <li> j)	Asegurar el cumplimiento de los puntos que le competen como cliente, contenidos en la guía de servicio, el cual forma parte integral del presente contrato. </li>
                                </ul>
                            </li>
                            <br>
                            <li>
                                <b> Derechos de “EL PROVEEDOR”.  </b><br>
                                “EL PROVEEDOR” tendrá, entre otros, los siguientes derechos:
                                <ul class="ul-list-ii">
                                    <li> a)	“EL PROVEEDOR” se reserva el derecho, más no la obligación, de inspeccionar previamente los Paquetes de “EL CLIENTE”; sin que esto exima a “EL CLIENTE” de responsabilidad por la naturaleza, empaque, peso o estado del Paquete.  </li>
                                    <li> b)	“EL PROVEEDOR” podrá discrecionalmente rechazar Paquetes que por sus dimensiones, destino o contenido no sean objeto de los Servicios. </li>
                                    <li> c)	Asimismo, “EL PROVEEDOR” a su exclusiva discreción determinará los medios de transporte y procedimientos que estime convenientes para la prestación del servicio. </li>
                                    <li> d)	Si el contenido del Paquete no concuerda con lo declarado o es de aquéllos comprendidos en la cláusula novena, “EL PROVEEDOR” queda automáticamente liberada de sus obligaciones y avisará a “EL CLIENTE” sobre el lugar y condiciones del Paquete para que éste asuma sus responsabilidades. </li>
                                    <li> e)	“EL PROVEEDOR” tendrá el derecho de retener el Paquete cuando “EL CLIENTE” le adeude cargos por el servicio contratado, sin perjuicio de que éste deduzca las acciones que le correspondan. </li>
                                </ul>
                            </li>
                            <br>
                            <li>
                                <b> Responsabilidad por servicio. </b><br>
                                En caso de pérdida, daño o menoscabo al Paquete, la indemnización que pagará “EL PROVEEDOR” 
                                a “EL CLIENTE” estará limitada a un monto máximo equivalente a 10 (diez) veces la (UMA) a la fecha 
                                del evento por Paquete, previa justificación por parte de “EL CLIENTE” del valor de adquisición de este.
                            </li>
                            <br>
                            <li>
                                <b> Seguro opcional. </b><br>
                                El límite de responsabilidad señalado en la cláusula anterior no será aplicable en caso de que “EL 
                                CLIENTE” declare el valor del Paquete y pague el seguro correspondiente en términos de lo 
                                establecido en la guía de servicio, en el entendido que será responsabilidad de “EL CLIENTE” 
                                proporcionar la documentación e información necesaria para tramitar el rembolso de la reclamación 
                                correspondiente.
                                <br>
                                Asimismo, la responsabilidad de “EL PROVEEDOR” en dicho caso de encontrarse asegurado el 
                                Paquete, estará limitada al monto efectivamente recuperado por “EL PROVEEDOR”.
                            </li>
                            <br>
                            <li>
                                <b> Exclusión de responsabilidad.  </b><br>
                                En ningún caso “EL PROVEEDOR” será responsable por daños indirectos, consecuenciales y punitivos.
                                Asimismo “EL PROVEEDOR” no será responsable por daños o pérdidas ocasionadas a 
                                “EL CLIENTE”, el remitente, destinatario o cualquier tercero, por causas ajenas a “EL PROVEEDOR” 
                                incluyendo sin limitar, casos fortuitos y de fuerza mayor, robo y robo calificado o con violencia , daño 
                                eléctrico o magnético, borradura de cualquier tipo información contenida en discos o similares, 
                                imágenes electrónicas, fotográficas, medicamentos que requieran refrigeración, o cualquier otro, 
                                daños, mala entrega, no entrega o entrega demorada del servicio contratado a causa de:
                                <ul class="ul-list-ii">
                                    <li> a)	Huelgas, motines, guerra, insurrecciones, marchas, plantones, manifestaciones, revueltas, peregrinaciones, fenómenos meteorológicos o cualquier otra causa ajena a “la Compañía”. </li>
                                    <li> b)	Por actos o disposiciones de cualquier autoridad, guerra o subversión guerrillera. </li>
                                    <li> c)	Información incorrecta o incompleta de los datos especificado por “el Cliente”. </li>
                                    <li> d)	Aquéllos señalados en el artículo 27 del Reglamento de Paquetería y Mensajería. </li>
                                </ul>
                            </li>
                            <li>
                                <b> Reclamaciones.  </b><br>
                                En caso de reclamaciones por extravíos parciales o daños, “EL CLIENTE” deberá presentar su 
                                reclamación por escrito al momento de recibir el Paquete, en el acuse respectivo. En caso de extravío 
                                total del Paquete “EL CLIENTE” deberá presentar su reclamación por escrito a “EL PROVEEDOR” 
                                donde se llevó a cabo la contratación dentro de los 30 (treinta) días naturales siguientes a la fecha 
                                de solicitud del servicio.
                            </li>
                            <br>
                            <li>
                                <b> Artículos prohibidos. </b><br>
                                “EL CLIENTE” se obliga y garantiza que no entregará para envíos objeto de los servicios de “EL 
                                PROVEEDOR” los siguientes artículos: dinero, billetes, instrumentos o títulos de crédito al portador o 
                                negociables, joyas o metales preciosos, antigüedades o piezas de arte, bebidas alcohólicas, armas 
                                de cualquier tipo, explosivos, materiales y residuos peligrosos, vegetales, animales o perecederos de 
                                cualquier tipo, materiales obscenos u ofensivos o de contrabando, psicotrópicos o estupefacientes y 
                                demás considerados en el artículo 234 de la Ley General de Salud, así como objetos que por su 
                                naturaleza pongan en peligro la integridad del personal que las manipule o la seguridad y legalidad 
                                de las aeronaves o medios de transporte.
                            </li>
                            <br>
                            <li>
                                <b> Condiciones de pago </b><br>
                                Los pagos serán realizados en un plazo máximo de: (i) 15 (quince) días naturales para el servicio de 
                                tipo consumo; y (ii) 2 (dos) días naturales para el servicio de tipo prepago; ambos contados a partir 
                                de presentada la factura, mediante cheque nominativo y sellado “para abono en cuenta del 
                                beneficiario”, a nombre de “EL PROVEEDOR” o mediante transferencia electrónica de fondos en la 
                                cuenta bancaria que para tales efectos indique “EL PROVEEDOR”. Cuando exista atraso en cualquier 
                                pago, “EL PROVEEDOR” podrá suspender inmediatamente el servicio sin ninguna responsabilidad y 
                                sin necesidad de resolución judicial alguna, aunado a la obligación de “EL CLIENTE” de pagar 
                                intereses moratorios a razón de la tasa TIIE más veinte puntos porcentuales por mes. 
                                <br>
                                Los precios para aplicar quedarán incluidos en la oferta del servicio negociada.
                                <br>
                                “EL PROVEEDOR” podrá modificar los precios de tiempo en tiempo, en cuyo caso lo notificará 
                                previamente a “EL CLIENTE”, considerándose tácitamente aceptada cualquier modificación cuando 
                                no exista manifestación en contrario, dentro de los 10 (diez) días hábiles posteriores a dicha notificación.
                            </li>
                            <br>
                            <li>
                                <b> Representante autorizado </b><br>
                                “EL CLIENTE” faculta y autoriza al tenedor de la guía para que en su nombre y representación 
                                acuerde con “EL PROVEEDOR” las instrucciones y condiciones operativas incluidas en la guía el 
                                acuerdo de servicio. Igualmente, a la aprobación de precios del servicio
                            </li>
                            <br>
                            <li>
                                <b> Subcontratación </b><br>
                                “EL PROVEEDOR” podrá subcontratar libremente los servicios objeto de este contrato sin necesidad 
                                de notificación o autorización alguna por parte de “EL CLIENTE”.
                            </li>
                            <br>
                            <li>
                                <b> Vigencia </b><br>
                                El presente contrato entrará en vigor a partir de la fecha de su firma y tendrá una vigencia de un año, 
                                renovándose automáticamente por periodos del mismo plazo. Cualquiera de las partes podrá terminar el 
                                presente contrato, mediante aviso por escrito a la otra con al menos 30 (treinta) días de anticipación. 
                                <br>
                                Independientemente de la vigencia del presente contrato, las guías deberán ser utilizadas y tendrán 
                                validez únicamente durante el año calendario de la fecha de su compra.
                            </li>
                            <br>
                            <li>
                                <b> Jurisdicción. </b><br>
                                Para la interpretación y cumplimiento del presente contrato, las partes se someten a la jurisdicción 
                                y competencia de los Tribunales de la Ciudad de México, o a los del domicilio de "EL CLIENTE" a 
                                elección de "EL PROVEEDOR", renunciando “EL CLIENTE” a cualquier fuero que por razón de su 
                                domicilio presente o futuro o por cualquier otra causa le pudiera corresponder.
                            </li>
                        </ol>
                    </p>
                </div>
            </div>
        </div>
      </section>
      <!-- Section End -->
</template>

<script>
export default {
    name: 'terminosCondiciones'
}
</script>

<style scoped>

</style>