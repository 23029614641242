import indexComponent from "../pages/index.vue";
import articulosProhibidos from "../pages/articulosProhibidos.vue";
import avisoPrivacidad from "../pages/avisoPrivacidad.vue";
import terminosCondiciones from "../pages/terminosCondiciones";

export const routes = [
    {
        path: '/',
        component: indexComponent
    },
    {
        path: '/articulosProhibidos',
        component: articulosProhibidos
    },
    {
        path: '/avisoPrivacidad',
        component: avisoPrivacidad
    },
    { 
        path: '/terminosCondiciones', 
        component: terminosCondiciones
    },
]

