<template>
    <!-- carousel-area Section Start -->
    <section id="bada" class="carousel-area bg-blue-100 py-32">
        <div class="container" data-aos-mirror="true">
            <div class="text-center">
                <!-- <h2 class="mb-12 text-4xl text-white tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Clientes </h2> -->
            </div>
            <div class="flex">
                <div class="w-full relative text-blue-100">
                    <div class="bada-carousel flex flex-wrap">
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_01.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_02.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_03.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_04.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_05.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_06.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_07.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_08.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_09.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_10.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_11.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_12.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_13.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_14.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_15.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_16.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_17.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_18.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_19.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_20.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_21.png" alt="Bada"/>
                            </div>
                        </div>
                        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div class="px-6">
                                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/bada/bada_22.png" alt="Bada"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- carousel-area Section End -->
</template>

<script>
import { tns } from "tiny-slider";

export default {
    name: 'carouselComponent',
    mounted(){
      tns({
        container: '.bada-carousel',
        items: 3,
        slideBy: 1,
        autoplay: true,
        mouseDrag: true,
        gutter: 30,
        nav: true,
        navPosition: 'bottom',
        controls: false,
        responsive: {
          0: {
            items: 1,
          },
          765: {
            items: 2,
          },
          1024: {
            items: 3,
          }
        }
      });
    }
}
</script>

<style scoped>

</style>