<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
          <div>
            <h1>Modal Heading</h1>
          </div>
        </slot>
        </div>
        
        <slot name="content">
          <div class="relative">
            <p><span>Contenido a remplazar</span></p>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInfo"
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow-y: initial !important
}
.modal-container {
  width: 50%;
  height: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  padding-bottom: 10px;
  overflow-y: auto;
}
</style>