<template>
  <!-- carousel-area Section Start -->
  <section id="customer" class="carousel-area bg-blue py-32">
    <div class="container" data-aos-mirror="true">
      <div class="text-center">
        <h2 class="mb-12 text-4xl text-white tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Clientes </h2>
      </div>
      <div class="flex">
        <div class="w-full relative">
          <div class="customer-carousel flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_Dipak.png" alt="Dipak"/>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_MTMotors.png" alt="MT Motors"/>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_Moyo.png" alt="Moyo" />
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_SyD.png" alt="S y D"/>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_Euroglas.png" alt="Euroglas"/>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_ICAS.png" alt="Icas"/>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/customer/customer_Carrimex.png" alt="Carrimex"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- carousel-area Section End -->
</template>

<script type="module">
import { tns } from "tiny-slider";

export default {
    name: 'customerComponent',
    mounted(){
      tns({
        container: '.customer-carousel',
        items: 3,
        slideBy: 'page',
        autoplay: false,
        mouseDrag: true,
        gutter: 30,
        nav: true,
        navPosition: 'bottom',
        controls: false,
        responsive: {
          0: {
            items: 1,
          },
          765: {
            items: 2,
          },
          1024: {
            items: 3,
          }
        }
      });
    }
}
</script>

<style scoped>

</style>