<template>
    <section id="about" class="bg-white py-24" >   
        <div class="container" data-aos-mirror="true">
            <div class="flex flex-wrap justify-center" data-aos="fade-up">
                <div class="w-full md:w-1/2 lg:w-1/2">
                    <div class="w-3/4 mx-6">
                        <h2 class="text-4xl text-orange tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Misión </h2>
                        <p class="text-gray-700">
                            Diseñar soluciones integrales de logística en mensajería y paquetería, ofrecer alternativas de
                            servicio con calidad, así como un trato diferenciado, para generar sinergia en sus
                            procesos y un desarrollo organizacional competitivo en el mercado.
                        </p>
                    </div>
                    <div class="w-3/4 py-10 mx-6">
                        <h2 class="text-4xl text-orange tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Visión </h2>
                        <p class="text-gray-700">
                            Consolidarnos como un proveedor logístico y confiable en el ramo de mensajería y
                            paquetería, con clientes satisfechos así como contar con personal capacitado comprometido
                            con sus valores.
                        </p> 
                    </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/2">
                    <div class="w-3/4 py-10 mx-6">
                        <h2 class="text-4xl text-orange tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Valores </h2>
                        <ul class="ul-list-ii">
                            <li>
                                <b>Integridad</b>
                                <p>
                                    Relacionarse con nuestros clientes y las personas de una manera auténtica.
                                </p>
                            </li>
                            <li>
                                <b>Confianza</b>
                                <p>
                                    Interactuamos y tratamos conrespeto y cordialidad a clientes y proveedores.
                                </p>
                            </li>
                            <li>
                                <b>Honestidad</b>
                                <p>
                                    En todo momento anteponerse a la verdad de sus pensamientos, expresiones y acciones.
                                </p>
                            </li>
                            <li>
                                <b>Lealtad</b>
                                <p>
                                    Fortalecer y desarrollar la relación cliente-proveedor, a través de cumplir el nivel de servicio contratado.
                                </p>
                            </li>
                            <li>
                                <b>Agradecimiento</b>
                                <p>
                                    Reconocer con gratitud el desempeño de nuestras funciones laborales.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
     </section>

    
 </template>
 
 <script>
 export default {
     name: 'aboutUsComponent'
 }
 </script>
 
 <style scoped>
 
 </style>