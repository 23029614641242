<template>
    <!-- Technology Section Start -->
    <div id="technology" class="py-16 bg-blue-100" data-aos-mirror="true">
        <div class="container">
          <div class="text-center">
            <h2 class="mb-12 text-4xl text-blue tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Tecnología </h2>
          </div>
          <div class="flex flex-wrap justify-center">
            <div class="mx-3 mb-8 w-1/2 md:w-1/2 lg:w-1/2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="m-3 text-center" data-aos="fade-up">
                <h3 class="service-title uppercase">Documentación</h3>
                <p class="text-gray-600">
                  Contamos con seguimiento en línea y la generación de guías
                  electrónicas de servicio, con etiqueta térmica o papel bond
                  según sus necesidades.
                </p>
                <div class="text-5xl text-center">
                  <i class="lni lni-files"></i>
                </div>
              </div>
            </div>
  
            <div class="mx-3 mb-8 w-1/2 md:w-1/2 lg:w-1/2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="m-3 text-center" data-aos="fade-up">
                <h3 class="service-title uppercase">Trazabilidad</h3>
                <p class="text-gray-600">
                  Se provee de diversas opciones para el seguimiento de sus envíos
                  a través de nuestro dominio web, así como la explotación de
                  información para los procesos internos de su empresa.
                </p>
                <div class="text-5xl text-center">
                  <i class="lni lni-pencil"></i>
                </div>
              </div>
            </div>
          </div>
  
          <div class="flex flex-wrap justify-center">
            <div class="mx-3 mb-8 w-1/2 md:w-1/2 lg:w-1/2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="m-3 text-center" data-aos="fade-up">
                <h3 class="service-title uppercase">Integración</h3>
                <p class="text-gray-600">
                  Ofrecemos la posibilidad de integrar el sistema de control de
                  entregas con los sistemas de su empresa para optimizar la
                  atención del cliente final.
                </p>
                <div class="text-5xl text-center">
                  <i class="lni lni-microsoft"></i>
                </div>
              </div>
            </div>
  
            <div class="mx-3 mb-8 w-1/2 md:w-1/2 lg:w-1/2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="m-3 text-center" data-aos="fade-up">
                <h3 class="service-title uppercase">GPS</h3>
                <p class="text-gray-600">
                  Unidades equipadas con rastreo satelital en tiempo real y
                  Geo-Cercas, así como tracking e historial de recorrido de
                  unidades y rutas.
                </p>
                <div class="text-5xl text-center">
                  <i class="lni lni-world"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Technology Section End -->
</template>

<script>
export default {
    name: 'technologyComponent'
}
</script>

<style scoped>

</style>