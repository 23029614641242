<template>
    <!-- Footer Section Start -->
    <footer id="footer" class="bg-blue py-16" >
        <div class="container" data-aos-mirror="true">
            <div class="flex flex-wrap">
                <div class="w-full sm:w-1/3 md:w-1/3 lg:w-1/3" data-aos="zoom-in-down">
                    <div class="mx-3 mb-8">
                        <h3 class="text-xl text-white mb-5 uppercase">Envíos</h3>
                        <ul>
                            <li>
                                <a href="#" class="footer-links">
                                Cotización
                                </a>
                            </li>
                            <li>
                                <a href="https://sistema.logisticosbada.com/" class="footer-links" target="_blank">
                                Rastreo
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="w-full sm:w-1/3 md:w-1/3 lg:w-1/3" data-aos="zoom-in-down">
                    <div class="mx-3 mb-8">
                        <h3 class="text-xl text-white mb-5 uppercase">Enlaces adicionales</h3>
                        <ul>
                        <li>
                            <a class="footer-links" href="/articulosProhibidos"> Articulos Prohibidos </a>
                        </li>
                        <li>
                            <a class="footer-links" href="/avisoPrivacidad">Aviso de privacidad</a>
                        </li>
                        <li>
                            <a class="footer-links" href="/terminosCondiciones">Términos y condiciones</a>
                        </li>
                        </ul>
                    </div>
                </div>

                <div class="w-full sm:w-1/3 md:w-1/3 lg:w-1/3" data-aos="zoom-in-down">
                    <div class="mx-3 mb-8">
                        <h3 class="text-xl text-white mb-5 uppercase">Redes Sociales</h3>
                        <ul class="social-icons flex justify-start">
                        <li class="mx-2">
                            <a href="#" class="footer-icon hover:bg-red-500"> <!-- class="footer-links " -->
                            <i
                                class="lni lni-instagram-original"
                                aria-hidden="true"
                            ></i>
                            </a>
                        </li>
                        <li class="mx-2">
                            <a href="#" class="footer-icon hover:bg-indigo-500"> <!-- class="" -->
                            <i class="lni lni-facebook" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="mx-2">
                            <a href="#" class="footer-icon hover:bg-teal-green"> <!-- class="" -->
                            <i class="lni lni-whatsapp" aria-hidden="true"></i>
                            </a>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Section End -->
</template>

<script>
    export default{
        name: 'footerComponent'
    }
</script>

<style scoped>

</style>