<template>
    <!-- Coverage Section Start -->
    <section id="coverage" class="py-24">
      <div class="container" data-aos-mirror="true">
        <div class="text-center">
          <h2 class="mb-12 text-4xl text-orange tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Cobertura </h2>
        </div>
        <div class="flex flex-wrap contact-form-area justify-center" data-aos="fade-up">
          <div class="w-full md:w-1/2">
            <div class="px-6">
              <mexicoComponent />
            </div>
          </div>
  
          <div class="w-full md:w-1/2 py-20 text-gray-700">
            <ul class="ul-list">
              <li> Especializados en LTL de última milla en CDMX y Zona Metropolitana. </li>
              <br />
              <li>Contamos con una red de distribución en Aguascalientes, Guanajuato, Guerrero, Michoacán, Morelos, Puebla, Querétaro, San Luis Potosí, Tlaxcala, Veracruz y Yucatán.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
      <!-- Coverage Section End -->
</template>

<script>
  import mexicoComponent from './cobertura/mexico.vue';

  export default {
    name: 'coverageComponent',
    components: {
      mexicoComponent
    }
  }
</script>

<style scoped>
</style>