<template>
    <!-- Header Area wrapper Starts -->
    <header id="header-wrap" class="relative">
        <!-- Navbar Start -->
        <div class="navigation fixed top-0 left-0 w-full z-30 duration-300 bg-orange" id="menu">
          <div class="container">
            <nav class="navbar py-2 navbar-expand-lg flex justify-between items-center relative duration-300">
              <button v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}" class="navbar-toggler focus:outline-none lg:hidden" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
  
              <div v-bind:class="{show:navOpen}" class="collapse bg-orange hidden navbar-collapse lg:block duration-300 shadow absolute top-100 left-0 mt-full z-20 px-5 py-3 w-full lg:static lg:bg-transparent lg:shadow-none" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto justify-center items-center lg:flex">
                  <li class="nav-item">
                    <a class="page-scroll" href="/">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#about">Nosotros</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#services">Servicios</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#coverage">Cobertura</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#technology">Tecnología</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#customer">Clientes</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#contact">Contacto</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="https://sistema.logisticosbada.com/" target="_blank">Rastreo</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <!-- Navbar End -->
    </header>
</template>

<script>
  export default {
    name: 'headerComponent',
    data () { 
      return{
        navOpen: false
      }
    },
}
</script>

<style scoped>

</style>