<template>
    <section class="pt-48 pb-10">
        <div class="container" data-aos-mirror="true">
            <div class="text-center">
                <h2 class="mb-12 text-4xl text-gray-700 tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Artículos prohibidos </h2>
            </div>
            <div class="flex flex-wrap contact-form-area justify-center" data-aos="fade-down">
                <div class="w-full md:w-3/4 py-16 text-gray-700">
                    <p>
                        <strong> Artículo 18 </strong>
                        <br>
                        Al Permisionario le está prohibido utilizar los vehículos autorizados para el servicio de paquetería y mensajería, para brindar un servicio distinto. También le está prohibido transportar paquetes cuando se trate de:
                        <br><br>
                        <ul class="ul-list pl-3">
                            <li> I. Billetes o anuncios de lotería extranjera. </li>
                            <li> II. Materiales y residuos a los que hace referencia el Reglamento para el Transporte Terrestre de Materiales y Residuos Peligrosos, salvo que se obtenga la autorización correspondiente de la autoridad competente. </li>
                            <li> III. Psicotrópicos y estupefacientes, salvo que su posesión o traslado sea lícita conforme a las disposiciones legales aplicables. </li>
                            <li> IV. Armas de fuego y explosivos. </li>
                            <li> V.	Animales o perecederos, cuando no se cumplan las condiciones de higiene y seguridad adecuadas, de acuerdo con la normatividad aplicable. </li>
                            <li> VI. Dinero o títulos de crédito al portador o negociables. </li>
                            <li> VII.	Cualquier otro bien cuyo tránsito requiera de permiso específico o bien lo restrinja alguna ley en particular, sin que se cuente con dicho permiso específico, y </li>
                            <li> VIII. Cualquier otro bien que prohíban las leyes específicas de la materia. </li>
                        </ul>
                    </p>
                </div>
            </div>
            <div class="flex flex-wrap justify-center" data-aos="fade-down">
                <div class="flex flex-wrap justify-center">
                  <img class="p-8 rounded-t-lg shadow-lg max-w-full h-auto align-middle border-none undefined" src="../assets/img/articulos_Prohibidos.jpg" alt="Artículos prohibidos"/>
                </div>
            </div>
        </div>
      </section>
      <!-- Section End -->
</template>

<script>
export default {
    name: 'articulosPhohibidos'
}
</script>

<style scoped>

</style>