<template>
    <section class="pt-48 pb-10">
        <div class="container" data-aos-mirror="true">
            <div class="text-center">
                <h2 class="mb-12 text-4xl text-gray-700 tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Aviso de privacidad </h2>
                <div class="mb-12 text-gray-700 tracking-wide uppercase font-bold" data-aos="zoom-in-down">
                        PERSONA FISICA CON ACTIVIDAD EMPRESARIAL,<br>
                        Comercialmente Conocido Como Transportes Logísticos Bada
                </div>
            </div>
            <div class="flex flex-wrap contact-form-area justify-center" data-aos="fade-down">
                <div class="w-full md:w-3/4 py-8 text-gray-700">
                    <p>
                        Este Aviso describe la forma en que <b>recabamos y usamos los datos personales que usted nos brinda</b>, uso que será el <b>necesario, adecuado y relevante</b>
                        en relación con las finalidades determinadas previstas en este documento.
                        <br>
                        También describe <b>qué tipo de datos son y qué opciones tiene para limitar el uso de dichos datos</b>, así como el <b>procedimiento para ejercer sus derechos ARCO</b>, 
                        en cumplimiento a los principios de <b>Licitud, Consentimiento, Calidad, Información, Finalidad, Lealtad, Proporcionalidad y Responsabilidad</b> que la ley establece.
                        <br><br>
                        <ol class="ol-list pl-3">
                            <li>
                                <b>Responsables de la protección de sus datos personales. </b> <br>
                                <b>Transportes Logísticos Bada</b>, con domicilio operativo en:
                                <b>Granate No.112, Col. Estrella, C.P. 07810, Alcaldía Gustavo A. Madero, CDMX.</b> <br>
                                es responsable del tratamiento (uso) de sus Datos Personales.
                            </li>
                            <br>
                            <li>
                                <b>Nuestro Departamento de Protección de Datos</b><br>
                                <b>Área:</b> Ventas y servicio <br>
                                <b>Domicilio:</b> Granate No.112, Col. Estrella, C.P. 07810, Alcaldía Gustavo A. Madero, CDMX. <br>
                                <b>Correo electrónico: </b> aviso.privacidad@transportesbada.com <br>
                                <b>Teléfono: </b> 56 2132 9757
                            </li>
                            <br>
                            <li>
                                <b>¿Para que fines recabamos y utilizamos sus datos personales?</b><br>
                                Sus datos personales serán utilizados para las siguientes finalidades:
                                <ul class="ul-list-ii">
                                    <li> Proveer los productos solicitados. </li>
                                    <li> Proveer los servicios solicitados y/o contratados. </li>
                                    <li> Reconocer a sus requisitos de información, atención y servicio. </li>
                                    <li> Evaluar la calidad del nivel de servicio que le brindamos. </li>
                                    <li> Archivo de registros y expediente de la relación contractual para seguimiento de servicios futuros.</li>
                                    <li> Gestión financiera, facturación y cobro. </li>
                                    <li>Cumplimiento a las obligaciones y compromisos que hemos contraído con usted. </li>
                                </ul> 
                                <br>
                                Adicionalmente, sus datos personales podrán ser utilizados para:
                                <ul class="ul-list-ii">
                                    <li> Promocionar y comercialización de productos y servicios. </li>
                                    <li> Ofrecer nuevos productos y servicios a la medida de sus intereses y necesidades. </li>
                                    <li> Realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de estos. </li>
                                    <li> Comunicar sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos. </li>
                                    <li> Publicaciones y comunicados sobre nuestra organización. </li>
                                </ul>
                            </li>
                            <br>
                            <li>
                                <b>¿Qué datos personales obtenemos y de dónde?</b>
                                Para los fines señaladas anteriormente podemos recabar sus datos personales de distintas formas: <br>
                                <ul class="ul-list-ii">
                                    <li> Cuando visita nuestra página de Internet o utiliza nuestros servicios en línea. </li>
                                    <li> Por teléfono o fax </li>
                                    <li> Cuando usted proporciona datos personalmente. </li>
                                    <li> Cuando obtenemos información a través de otras fuentes de acceso público que están permitidas por la ley (Directorios telefónicos o laborales, sitios web, etc). </li>
                                </ul>
                                <br>
                                <b>Datos personales que recabamos:</b>
                                <ul class="ul-list-ii">
                                    <li> Información de contacto (Nombre, Correo Electrónico, Dirección, Teléfono, Celular, Fax). </li>
                                    <li> Documentación de identidad (Credencial de Elector, Pasaporte, Licencia de Manejo, Cartilla o Cédula Profesional). </li>
                                    <li> Información financiera y medios de pago (Tarjeta de crédito, débito, cheques, transferencias). </li>
                                    <li> Información Fiscal (RFC, Domicilio Fiscal). </li>
                                    <li> Identificación en Redes Sociales (Facebook, Twitter, LinkedIn, Google). </li>
                                    <li> Datos socioeconómicos (Edad, Sexo, Nivel de ingresos, Escolaridad). </li>
                                    <li> Número de Seguridad Social (NSS) y/o CURP. </li>
                                    <li> Historia académica (certificados escolares, constancias, estudios realizados). </li>
                                    <li> Beneficiarios y dependientes económicos. </li>
                                    <li> Información familiar (Padres, hermanos, cónyuge, hijos). </li>
                                    <li> Referencias personales (Información de personas no familiares). </li>
                                    <li> Información laboral (Empresa, Puesto, actividad desarrollada, CV). </li>
                                    <li> Hábitos, deportes, pasatiempos y aficiones. </li>
                                    
                                </ul>
                                <br>
                                <b> Uso de cookies, web beacons y JavaScript. </b> <br>
                                Una cookie es información que un sitio web almacena en su computadora mediante el uso de un navegador o explorador de internet. 
                                Una cookie permite que los sitios web registren sus actividades de navegación en internet – como, por ejemplo, cuáles son las páginas y contenidos que estuvo mirando, cuándo los visitó, qué buscó, y si usted hizo clic sobre algún recurso.
                                Los datos recolectados por las cookies se pueden combinar para crear un perfil de sus actividades en internet.
                                <br>
                                Las webs beacons en conjunto con los Java Scripts de métricas web permiten almacenar información sobre los patrones de uso de nuestro sitio web.
                                Le informamos que utilizamos esas herramientas para obtener información estadística como la siguiente:
                                Tipo de navegador y sistema operativo, las páginas de Internet consultadas, origen de la visita, la dirección IP de acceso, tiempo de permanencia en nuestro sitio, entre otros datos estadísticos.
                                <br>
                                Las cookies, el JavaScript y otras tecnologías de seguimiento y rastreo pueden ser deshabilitadas, sin embargo, desactivarlas puede generar que el sitio web o algunas de sus funcionalidades no se desempeñen adecuadamente.
                                <br>
                                Para revisar las configuraciones y en su caso deshabilitarlas, use la pestaña ‘Ayuda’ (Help), o busque en ‘Herramientas’ (Tools) la configuración de ‘Opciones’ (Options) o ‘Privacidad’ (Privacy) de su navegador.
                                Desde allí, puede eliminar las cookies, o controlar en qué caso usted permite instalarlas. Hay algunos navegadores que permiten instalar herramientas software complementarias (add-on software tools) para bloquear, eliminar o controlar las cookies.
                                Y generalmente, los programas de seguridad incluyen opciones para facilitar el control de las cookies.
                            </li>
                            <br>
                            <li>
                                <b>¿Cómo Acceder, Rectificar, Cancelar y Oponerse al uso y tratamiento de sus datos personales (Derechos ARCO)?</b><br>
                                Usted tiene derecho constitucional de <b>Acceder</b> y conocer los datos personales que poseemos y a los detalles del tratamiento y uso de los mismos, así como a <b>Rectificarlos</b> y corregirlos en caso de ser inexactos o incompletos; <b>Cancelarlos</b> y eliminarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, o que estén siendo utilizados para finalidades no autorizadas por usted o haya finalizado la relación jurídica o de servicio, o bien, <b>Oponerse</b> al tratamiento de los mismos para fines específicos. <br>
                                Los mecanismos implementados para el ejercicio de sus Derechos ARCO, así como los plazos, información y documentación que debe contener su solicitud se especifican en el apartado de <b>Derechos del Titular (10) localizado en este mismo documento.</b>
                            </li>
                            <br>
                            <li>
                                <b>¿Cómo puede limitar el uso o divulgación de sus datos personales?</b><br>
                                Si usted no desea recibir mensajes de nuestra parte que <b>no sean indispensables para cumplir las finalidades señaladas</b>, los mecanismos implementados para ello, así como los plazos, información y documentación que debe contener su solicitud se especifican en el apartado de <b>Derechos del Titular (10) localizado en este mismo documento.</b>
                            </li>
                            <br>
                            <li>
                                <b>¿Cómo revocar el consentimiento para el tratamiento de sus datos personales?</b><br>
                                Si usted desea <b>revocar el consentimiento que nos otorgó para el uso de sus datos personales</b>, los mecanismos implementados para ello, así como los plazos, información y documentación que debe contener su solicitud se especifican en el apartado de <b>Derechos del Titular (10) localizado en este mismo documento.</b>
                            </li>
                            <br>
                            <li>
                                <b>Transferencia de datos personales</b><br>
                                A continuación, le comunicamos que las siguientes transferencias <b>no requieren de su consentimiento, pero la ley exige que le sean informadas.</b><br>
                                En este sentido sus datos personales podrán ser compartidos a:
                                <ul class="ul-list-ii">
                                    <li> Sociedades del mismo grupo del responsable que opere bajo los mismos procesos y políticas internas. (Sociedades controladoras, subsidiarias, afiliadas, franquicias), para dar cumplimiento a la relación jurídica adquirida con el Titular. </li>
                                    <li> Entidades del sector público, para dar cumplimiento a la legislación vigente, así como a la relación jurídica con el Titular. </li>
                                    <li> Entidades públicas dedicadas a la prestación de diagnóstico médico, prestación de asistencia sanitaria, tratamiento médico o gestión de servicios sanitarios (Hospitales, médicos, laboratorios), para llevar a cabo todas las gestiones necesarias en caso de emergencia médica. </li>
                                </ul>
                            </li>
                            <br>
                            <li>
                                <b>Modificaciones al aviso de privacidad</b><br>
                                Nos reservamos el derecho de efectuar en cualquier momento modificaciones al presente Aviso de Privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para cumplir con las finalidades previstas. Estas modificaciones estarán disponibles a los Titulares en la dirección web: <a href="https://www.transportesbada.com/" target="_blank">www.transportesbada.com</a> 
                            </li>
                            <br>
                            <li>
                                <b>Derechos del Titular</b><br>
                                <b>Transportes Logísticos Bada </b> ha implementado el siguiente <b>Formato Universal de Ejercicio de Derechos del Titular respecto de sus Datos Personales.</b> <br><br>
                                <b>Instrucciones</b><br>
                                1. Descargue aquí el Formato Universal (PDF) <br>
                                2. Lea atentamente los requisitos e indicaciones que se describen en el formato. <br>
                                3. Envíe la solicitud al correo electrónico: aviso.privacidad@transportesbada.com. <br>
                                4. Confirme la recepción de su solicitud al teléfono: 56 21 32 97 57 <br>
                            </li>
                            <br>
                            <li>
                                <b>¿Ante quién puede presentar sus quejas o denuncias por el tratamiento indebido de sus datos personales?</b><br>
                                Si después de haber ejercido sus <b>Derechos de Protección de Datos ante Transportes Logísticos Bada</b> por medio de los mecanismos establecidos en este <b>Aviso de Privacidad</b>, considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte; o cuenta con evidencia de que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la <b>LFPDPPP</b>, le invitamos a <b>ponerse en contacto nuevamente con nosotros para agotar todos los procedimientos internos</b> a efecto de satisfacer plenamente su solicitud. De no ser el caso, usted podrá interponer la queja correspondiente ante el INAI. Para más información visite: <a href="https://www.inai.org.mx" target="_blank">www.inai.org.mx</a> 
                            </li>
                        </ol>
                    </p>
                </div>
            </div>
        </div>
      </section>
      <!-- Section End -->
</template>

<script>
export default {
    name: 'avisoPrivacidad'
}
</script>

<style scoped>

</style>