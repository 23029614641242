<template>
    <div>
        <checkbox-svg-map 
            v-model="selectedLocations"
            :map="Mexico" 
            @click="clickLocation"
            @mouseover="pointLocation"
            @mousemove="moveOnLocation"
            @mouseout="unpointLocation"
        />
        <div
			class="svg-map__tooltip"
			:style="tooltipStyle"
		>
			{{ pointedLocation }}
		</div>
        <div v-if="showModal">
            <modalInfo>
                <template #header>
                    Cobertura {{locacion}}
                    <button type="button" class="close" data-dismiss="modal" @click="showModal = false"> X </button>    
                </template>
                <template #content>
                    <table>
                        <tbody v-if="locacion === 'Estado de México'">
                            <tr v-for="zona in coberturaMexico " :key="zona.No">
                                <td>{{ zona.No }}</td> 
                                <td>{{ zona.Alcaldia }}</td>  
                            </tr>
                        </tbody>
                        <tbody v-if="locacion === 'CDMX'">
                            <tr v-for="zona in coberturaCDMX " :key="zona.No">
                                <td>{{ zona.No }}</td> 
                                <td>{{ zona.Alcaldia }}</td>  
                            </tr>
                        </tbody>
                    </table>                                    
                </template>
            </modalInfo>
        </div>
    </div>  
</template>
 
<script>
import { CheckboxSvgMap } from "vue-svg-map";
import Mexico from "@svg-maps/mexico/index";
import { getLocationName } from '../../utils/coverage-map'
import modalInfo from "./modalInfo.vue";
 
export default {
    name: "mexicoComponent",
    components: {
        CheckboxSvgMap,
        modalInfo
    },
    data() {
        return {
            Mexico,
            selectedLocations: [
                "agu", "cmx", "gua", "gro", "mex", "mic", "mor", "pue", "que", "slp", "tla", "ver", "yuc"
            ],
            clickedLocation: null,
            tooltipStyle: null,
            pointedLocation: null,
            showModal: false,
            locacion: "",
            coberturaCDMX: [
                { "No" : 1, "Alcaldia" : "Álvaro Obregón" },
                { "No" : 2, "Alcaldia" : "Azcapotzalco" },
                { "No" : 3, "Alcaldia" : "Benito Juárez" },
                { "No" : 4, "Alcaldia" : "Coyoacán" },
                { "No" : 5, "Alcaldia" : "Cuajimalpa de Morelos" },
                { "No" : 6, "Alcaldia" : "Cuauhtémoc" },
                { "No" : 7, "Alcaldia" : "Gustavo A. Madero" },
                { "No" : 8, "Alcaldia" : "Iztacalco" },
                { "No" : 9, "Alcaldia" : "Iztapalapa" },
                { "No" : 10, "Alcaldia" : "La Magdalena Contreras" },
                { "No" : 11, "Alcaldia" : "Miguel Hidalgo" },
                { "No" : 12, "Alcaldia" : "Milpa Alta" },
                { "No" : 13, "Alcaldia" : "Tláhuac" },
                { "No" : 14, "Alcaldia" : "Tlalpan" },
                { "No" : 15, "Alcaldia" : "Venustiano Carranza" },
                { "No" : 16, "Alcaldia" : "Xochimilco" }
            ],
            coberturaMexico: [
                { "No" : 1, "Alcaldia" : "Chalco de Díaz Covarrubias" },
                { "No" : 2, "Alcaldia" : "Chicoloapan de Juárez" },
                { "No" : 3, "Alcaldia" : "Chinconcuac" },
                { "No" : 4, "Alcaldia" : "Coacalco de Berriozábal" },
                { "No" : 5, "Alcaldia" : "Cuautitlán" },
                { "No" : 6, "Alcaldia" : "Cuautitlán Izcalli" },
                { "No" : 7, "Alcaldia" : "Ecatepec de Morelos" },
                { "No" : 8, "Alcaldia" : "Huixquilucan" },
                { "No" : 9, "Alcaldia" : "Ixtapaluca" },
                { "No" : 10, "Alcaldia" : "La Paz" },
                { "No" : 11, "Alcaldia" : "Naucalpan de Juárez" },
                { "No" : 12, "Alcaldia" : "Nezahualcóyotl" },
                { "No" : 13, "Alcaldia" : "Tecámac" },
                { "No" : 14, "Alcaldia" : "Texcoco" },
                { "No" : 15, "Alcaldia" : "Tlanepantla de Baz" },
                { "No" : 16, "Alcaldia" : "Tultitlan" },
                { "No" : 17, "Alcaldia" : "Metepec" },
                { "No" : 18, "Alcaldia" : "Lerma" },
                { "No" : 19, "Alcaldia" : "Ocoyoacac" },
                { "No" : 20, "Alcaldia" : "San Mateo Atenco" },
                { "No" : 21, "Alcaldia" : "Toluca de Lerdo" }
            ]

        };
    },
	methods: {
		clickLocation(event) {
			this.clickedLocation = getLocationName(event.target)

            if(this.clickedLocation === "Mexico City"){
                this.showModal = true
                this.locacion = "CDMX"
            }
            if(this.clickedLocation === "México"){
                this.showModal = true
                this.locacion = "Estado de México"
            }
			//window.open(URL[event.target.id], '_blank')
		},
        pointLocation(event) {
			this.pointedLocation = getLocationName(event.target)
		},
        unpointLocation() {
			this.pointedLocation = null
			this.tooltipStyle = { display: 'none' }
		},
        moveOnLocation(event) {
			this.tooltipStyle = {
				display: 'block',
				top: `${event.clientY + 10}px`,
				left: `${event.clientX - 100}px`,
			}
		}
    }
};
</script>
 
<style>
    .svg-map {
        width: 95%;
        height: auto;
        stroke: #7c7c7c;
        stroke-width: 1;
        fill: #CDCDCD;
    }
    .svg-map__location:focus, .svg-map__location:hover{
        outline: 0;
    }
    #agu, #cmx, #gua, #gro, #mex, #mic, #mor, #pue, #que, #slp, #tla, #ver, #yuc{
        fill: #ebf8ff;
    }
    #cmx:hover, #mex:hover{
        fill: #63b3ed;
        outline: 0;
    }
    .svg-map__tooltip {
        position: fixed;
        width: 200px;
        padding: 10px;
        text-align: center;
        font-family: 'Franklin Gothic Medium';
    }
</style>