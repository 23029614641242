<template>
    <!-- Contact Section Start -->
    <section id="contact" class="py-24" >
        <div class="container" data-aos-mirror="true">
          <div class="text-center">
            <h2 class="mb-12 text-4xl tracking-wide uppercase font-bold text-orange" data-aos="zoom-in-down"> Contacto </h2>
          </div>
  
          <div class="flex flex-wrap contact-form-area" data-aos="fade-up">
            <div class="w-full md:w-2/3">
              <div class="contact">
                <h2 class="uppercase font-bold text-xl text-blue mb-5 ml-3 uppercase"> ENVÍANOS UN MENSAJE </h2>
                <form id="contactForm" action="assets/contact.php">
                  <div class="flex flex-wrap">
                    <div class="w-full sm:w-1/2 md:w-full lg:w-1/2">
                      <div class="mx-3">
                        <input type="text" class="form-input rounded-full" id="name" name="name" placeholder="NOMBRE" required data-error="Por favor, escribe tu nombre"/>
                      </div>
                    </div>
                    <div class="w-full sm:w-1/2 md:w-full lg:w-1/2">
                      <div class="mx-3">
                        <input type="text" placeholder="CORREO" id="email" class="form-input rounded-full" name="email" required data-error="Por favor, escribe tu correo"/>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="mx-3">
                        <input type="text" placeholder="ASUNTO" id="subject" name="subject" class="form-input rounded-full" required data-error="Por favor, escribe el asunto"/>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="mx-3">
                        <textarea class="form-input rounded-lg" id="message" name="message" placeholder="ESCRIBE TU MENSAJE" rows="5" data-error="Por favor, escribe tu mensaje" required></textarea>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="submit-button mx-3">
                        <button class="btn uppercase" id="form-submit" type="submit"> Enviar </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
  
            <div class="md:w-1/3">
              <div class="ml-3 md:ml-12 fadeIn">
                <h2 class="uppercase font-bold text-xl text-gray-700 mb-5"></h2>
                <div>
                  <div class="flex flex-wrap mb-6 items-center">
                    <div class="contact-icon">
                      <i class="lni lni-envelope"></i>
                    </div>
                    <p class="pl-3">
                      <a href="#" class="block">correo@email.com</a>
                    </p>
                  </div>
  
                  <div class="flex flex-wrap mb-6 items-center">
                    <div class="contact-icon">
                      <i class="lni lni-phone"></i>
                    </div>
                    <p class="pl-3">
                      <a href="#" class="block">5555555555</a>
                    </p>
                  </div>
  
                  <div class="flex flex-wrap mb-6 items-center">
                    <div class="contact-icon">
                      <i class="lni lni-map-marker"></i>
                    </div>
                    <p class="pl-3">
                      Granate No.112, <br />
                      Col. Estrella, C.P. 07810, <br />
                      Alcaldía Gustavo A. Madero, CDMX.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Contact Section End -->
</template>

<script>
export default {
    name: 'contactCompoenent'
}
</script>

<style scoped>

</style>