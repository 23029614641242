<template>
    <!-- Services Section Start -->
    <div id="services" class="py-16 bg-blue" data-aos-mirror="true">
      <div class="container">
        <div class="text-center">
          <h2 class="mb-12 text-4xl text-white tracking-wide uppercase font-bold" data-aos="zoom-in-down"> Servicios </h2>
        </div>
        <div class="flex flex-wrap justify-center">
          <div class="flip mx-3 mb-8 w-1/4 md:w-1/4 lg:w-1/4 block max-w-sm p-6" data-aos="fade-up">
            <div class="flip-content border border-gray-200 shadow bg-white border border-gray-200 rounded-lg shadow">
              <div class="flip-front">
                <i class="lni lni-envelope text-4xl">
                  <h3 class="service-title"> Mensajería </h3>
                </i>
              </div>
              <div class="flip-back">
                <p class="text-gray-600">
                  Servicio con recolección programada y entrega de 24:00 a 72:00hrs según cobertura.
                </p>
              </div>
            </div>
          </div> 
          <div class="flip mx-3 mb-8 w-1/4 md:w-1/4 lg:w-1/4 block max-w-sm p-6" data-aos="fade-up">
            <div class="flip-content border border-gray-200 shadow bg-white border border-gray-200 rounded-lg shadow">
              <div class="flip-front">
                <i class="lni lni-package text-4xl">
                  <h3 class="service-title"> Paquetería </h3>
                </i>
              </div>
              <div class="flip-back">
                <p class="text-gray-600">
                  Servicio LTL con recolección programada de paquetes hasta 30kg y kilos adicionales entrega de 24:00 a 72:00 hrs según cobertura.
                </p>
              </div>
            </div>
          </div> 
          <div class="flip mx-3 mb-8 w-1/4 md:w-1/4 lg:w-1/4 block max-w-sm p-6" data-aos="fade-up">
            <div class="flip-content border border-gray-200 shadow bg-white border border-gray-200 rounded-lg shadow">
              <div class="flip-front ">
                <i class="lni lni-reload text-4xl">
                  <h3 class="service-title"> Rutas dedicadas </h3>
                </i>
              </div>
              <div class="flip-back">
                <p class="text-gray-600">
                  Unidades exclusivas para 08:00hrs de servicio en unidades de 1.5 Ton. con operador acompañante, viajes directos en CDMX y principales ciudades del interior.
                </p>
              </div>
            </div>
          </div> 
          <div class="flip mx-3 mb-8 w-1/4 md:w-1/4 lg:w-1/4 block max-w-sm p-6" data-aos="fade-up">
            <div class="flip-content border border-gray-200 shadow bg-white border border-gray-200 rounded-lg shadow">
              <div class="flip-front ">
                <i class="lni lni-chevron-right-circle text-4xl">
                  <h3 class="service-title"> Logística </h3>
                </i>
              </div>
              <div class="flip-back">
                <p class="text-gray-600">
                  Distribución especializada con documentos de retorno, tracking y acuses electrónicos, courier house, preparación y maquila de envíos.
                </p>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <!-- Services Section End -->
</template>

<script>
export default {
    name: 'servicesComponent'
}
</script>

<style scoped>
.flip {
  width: 280px;
  height: 250px;
  text-align: center;
  perspective: 600px;  
}
.flip-content {
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}
.flip-front, .flip-back {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;  
  align-items: center;
  display: flex;
  justify-content: center;
}
.flip-back {
  transform: rotateY(180deg);
  padding: 1rem;
}
</style>