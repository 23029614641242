<template>
    <main>
        <homeComponent/>
        <carouselComponent/>
        <aboutUsComponent/>
        <servicesComponent/>
        <coverageComponent/>
        <technologyComponent/>
        <customerComponent/>
        <contactComponent/>
      </main>
</template>

<script>
    import homeComponent from '../components/home.vue'
    import carouselComponent from '../components/carousel.vue'
    import aboutUsComponent from '../components/aboutUs.vue'
    import servicesComponent from '../components/services.vue'
    import coverageComponent from '../components/coverage.vue'
    import technologyComponent from '../components/technology.vue'
    import customerComponent from '../components/customer.vue'
    import contactComponent from '../components/contact.vue'

    export default {
        name: 'indexComponent',
        components: {
            homeComponent,
            carouselComponent,
            aboutUsComponent,
            servicesComponent,
            coverageComponent,
            technologyComponent,
            customerComponent,
            contactComponent
        }   
    }
</script>

<style scoped>

</style>